$(document).ready(function () {

  // Animate loader off screen
  $('.loader').fadeOut(400);

  // animer en cascade les enfants du container animate-container
  var i;

  $(".animate-container").each(
    function () {
      for (i = 0; i < $(this).children().length; i++) {
        var animation = $(this).data('animation');
        var duration = $(this).data('dur');

        $(this).children().addClass('animate').attr({
          "data-animate": animation,
          "data-duration": duration,
        });

        var wrapperChildren = $(this).children();
        for (var i = 0; i <= wrapperChildren.length; i++) {

          let delaiString = $(this).data('base');
          delaiString += $(this).data('increment') * i;
          $(wrapperChildren[i]).attr("data-delay", delaiString + "s");
        }

      }
    });

  //initialiser les animations Scrolla
  $('.animate').scrolla({
    once: true,
  });

  //Smooth scroll pour les mobiles avec Safari
  if (window.matchMedia("(max-width: 1024px)").matches) {
    // Add smooth scrolling to all links
    $("a").on('click', function (event) {

      // Make sure this.hash has a value before overriding default behavior
      if (this.hash !== "") {
        // Prevent default anchor click behavior
        event.preventDefault();

        // Store hash
        var hash = this.hash;

        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        $('html, body').animate({
          scrollTop: $(hash).offset().top
        }, 800, function () {

          // Add hash (#) to URL when done scrolling (default click behavior)
          window.location.hash = hash;
        });
      } // End if
    });
  }

  //ferme le bouton menu si un lien est cliqué dans le menu mobile
  $('nav ul li a').click(function () {
    $('body').css('overflow-y', 'inherit');
    $('.menu-btn').removeClass('active');
  });

  //changer le 'state' du bouton menu dépendant du menu ouvert ou fermé
  $('.site-overlay').click(function () {
    $('.menu-btn').removeClass('active');
  });

    //sticky menu
  //Logo apparaît sur scroll
 //Logo apparaît sur scroll
  $(window).on('scroll', function () {
  if ($(this).scrollTop() > 50) {
    $("header").addClass("stickyHeader");
    $(".icon-back-to-top").addClass("active");
  } 
  if ($(this).scrollTop() == 0) {
    $("header").removeClass("stickyHeader");
    $(".icon-back-to-top").removeClass("active");
  }
});

  var swiper = new Swiper(".swiper-testimonial", {
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

});